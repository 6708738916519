<template>
  <div class="p-5 h-100 d-flex justify-content-center align-items-center">
    <div class="text-center">
      <div class="h1">Acceso Restringido</div>
      <div>
        No tienes los permisos para acceder a la ruta
        <b>{{ $route.query.route }}</b>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RestrictedAccess"
};
</script>
